import { queryId } from '@ably/ui/core/dom-query';

/*
  A method to enable/disable a CTA that
  is used to open the HubSpot chat widget

  If the Chat widget is unavailable this script
  will apply a disabled property and change
  the text on the button, to warn the user.

  Params: (one or the other is required)
  - dataId?    the parent container data-id
  - container? the parent container element
*/

function setInnerTextPreserveIcon(el, text) {
  const withIcon = el.querySelector('svg');

  if (withIcon) {
    const innerHtml = el.innerHTML;
    const updatedHtml = innerHtml.replace(/(<\/svg>.*)$/, `</svg>${text}`);
    el.innerHTML = updatedHtml;
  } else {
    el.innerText = text;
  }
}

function enableBtn(el, text) {
  el.disabled = false;
  setInnerTextPreserveIcon(el, text);
}

function disableBtn(el, text) {
  el.disabled = true;
  setInnerTextPreserveIcon(el, text);
}

const WAIT_BETWEEN_RETRIES_MS = 100;

export default function toggleChatWidget(params = {}) {
  const { dataId } = params;
  const container = params.container || queryId(dataId);
  const chatButton = queryId('open-chat-widget', container);
  const textEnabled = chatButton.dataset.enabledLabel;
  const textDisabled = chatButton.dataset.disabledLabel;
  let MAX_RETRY_COUNT = 30;

  if (!container || !chatButton) return () => {};

  let clickHandler;

  const waitForScript = (delay) => {
    const widget = window?.HubSpotConversations?.widget;

    // If the chat is set to be hidden out of hours this will return null
    const iframe = document.querySelector('#hubspot-messages-iframe-container');

    clickHandler = (e) => {
      e.preventDefault();
      if (!widget?.status().loaded) {
        window?.HubSpotConversations?.widget.load();
      }

      window?.HubSpotConversations?.widget.open();
    };

    const widgetIsHidden = !window.hsConversationsSettings?.loadImmediately;

    if (widget && (iframe || widgetIsHidden)) {
      chatButton.addEventListener('click', clickHandler);
      // Use requestAnimationFrame to ensure we update the button after
      // the current DOM rendering has completed, because on fast networks
      // the rendering process might be slower than the script, clobbering
      // our changes here
      requestAnimationFrame(() => enableBtn(chatButton, textEnabled));
    } else if (MAX_RETRY_COUNT > 0) {
      MAX_RETRY_COUNT -= 1;
      setTimeout(() => waitForScript(WAIT_BETWEEN_RETRIES_MS), delay);
    }
  };

  disableBtn(chatButton, textDisabled);
  waitForScript(0);

  return () => {
    disableBtn(chatButton, textDisabled);
    if (clickHandler) chatButton.removeEventListener('click', clickHandler);
  };
}
